import React from "react";

import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { css } from "@emotion/core";

const NotFoundPage = () => (
  <Layout>
    <div
      css={css`
        text-align: center;
      `}
    >
      <SEO title="404: Ta strona nie istnieje..." />
      <h1>404: Ta strona nie istnieje...</h1>
      <Link to="/">Strona główna</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
